$_COOKIE = {};
eval((document.cookie + ';').replace(/([\w%]+)=?([^;]*);/g, "$$_COOKIE['$1']='$2';"));

function setCookieF(name, value, expires, path, domain, secure) {
   document.cookie = name + '=' + escape(value || '') + ( expires ? ';expires=' + new Date(+new Date() + expires * 864e5).toGMTString() : '') + "; path=/" + ( domain ? ';domain=' + domain : '') + ( secure ? ';secure' : '');
}

function setFontSizeCookie(size) {
   //alert(size);
   setCookieF('fontSize', size);
   $_COOKIE.fontSize = size;
   reSize(size);
   setFontSize();
   return false;
}

function setFontSize() {
   if ($_COOKIE.fontSize != "undefined") {
      document.documentElement.className = $_COOKIE.fontSize;
      var size = $_COOKIE.fontSize;
      reSize(size);

   }

}

function reSize(size) {
   if (size == 'big_f') {
      //font
      $('.ab_font').addClass('a_font').removeClass('ab_font');
      $('.aab_font').addClass('aa_font').removeClass('aab_font');
      $('.aaa_font').addClass('aaab_font');
      $('.sprite.big').addClass('big--active').removeClass('big');
      $('.sprite.medium--active').addClass('medium').removeClass('medium--active');
      $('.sprite.small--active').addClass('small').removeClass('small--active');


   }
   if (size == 'medium_f') {
      //font
      $('.ab_font').addClass('a_font').removeClass('ab_font');
      $('.aaab_font').addClass('aaa_font').removeClass('aaab_font');
      $('.aa_font').addClass('aab_font');
      $('.sprite.medium').addClass('medium--active').removeClass('medium');
      $('.sprite.big--active').addClass('big').removeClass('big--active');
      $('.sprite.small--active').addClass('small').removeClass('small--active');



   }
   if (size == 'normal_f') {
      //font
      $('.a_font').addClass('ab_font').removeClass('a_font');
      $('.aab_font').addClass('aa_font').removeClass('aab_font');
      $('.aaab_font').addClass('aaa_font').removeClass('aaab_font');
      $('.sprite.small').addClass('small--active').removeClass('small');
      $('.sprite.big--active').addClass('big').removeClass('big--active');
      $('.sprite.medium--active').addClass('medium').removeClass('medium--active');
   }
}

//document.addEventListener("DOMContentLoaded", setFontSize, false);

if (!document.addEventListener) {
   document.attachEvent("DOMContentLoaded", setFontSize);
} else {
   document.addEventListener("DOMContentLoaded", setFontSize, false);
}

